<template>
  <div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-card>
          <md-card-header>
            <div class="md-title">Inicia Sesión en Filtroni</div>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-alignment-center">
              <div class="md-layout-item md-size-80">
                <md-field>
                  <label>Usuario</label>
                  <md-input v-model="$v.username.$model" autocomplete="off"></md-input>
                </md-field>
                <div v-if="$v.username.$dirty">
                  <span class="md-helper-text" v-if="!$v.username.required">Campo requerido</span>
                  <span class="md-helper-text" v-if="!$v.username.minLength">Minimo 4 caracteres</span>
                  <span class="md-helper-text" v-if="!$v.username.maxLength">Maximo de caracteres</span>
                  <span class="md-helper-text" v-if="!$v.username.email">No contiene formato de correo electrónico</span>
                </div>
                <md-field>
                  <label>Password</label>
                  <md-input v-model="$v.password.$model" type="password" autocomplete="off"></md-input>
                </md-field>
                <div v-if="$v.password.$dirty">
                  <span class="md-helper-text" v-if="!$v.password.required">Campo requerido</span>
                  <span class="md-helper-text" v-if="!$v.password.minLength">Minimo4 caracteres</span>
                  <span class="md-helper-text" v-if="!$v.password.maxLength">Maximo de caracteres</span>
                </div>
                <md-button class="md-raised" @click="login">Iniciar Sesión</md-button>
                <!-- Error -->
                <md-snackbar :md-position="'center'" :md-duration="4000" :md-active.sync="error" md-persistent>
                  <span>No se encontraron registros asociados</span>
                  <md-button class="md-primary" @click="error = false">Re-intentar</md-button>
                </md-snackbar>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-50">
        <p class="text-fix">En caso de ser un administrador y tener problemas de conexión, favor comunicarte directamente al <b>área de soporte</b></p>
      </div>
    </div>
  </div>
</template>
<script>
import List from '@/components/Brands/List.vue'
import validations from '@/validations/login'
import auth from '@/mixins/auth'
export default {
  name: 'Brands',
  mixins: [auth],
  components: {
    List
  },
  data() {
    return {
      username: '',
      password: '',
      error: false,
    }
  },
  validations: validations,
  methods: {
    login() {
      if (!this.$v.$invalid) {
        let user = {"email": this.username, "password": this.password}
        this.$store.dispatch('auth/authenticate', user).then(() => {
          this.$router.push({path: '/'})
        }).catch(() => {
          this.error = true
        })
      }
    }
  }
}
</script>
