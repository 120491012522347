import {required, maxLength, minLength, email} from 'vuelidate/lib/validators'

export default {
  username: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(50),
    email
  },
  password: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(12)
  }
}
